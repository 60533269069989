import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import PropTypes from 'prop-types';
import CustomLink from 'components/ui/link';
import * as Styled from './styled';

const AcademyCard = ({ img, title, content, link, linkContent, comingSoon }) => {
  return (
    <Styled.Card comingSoon={comingSoon} to={comingSoon ? null : link} as={comingSoon && 'div'}>
      <Styled.ImageBox>
        <Img Tag="div" fluid={img} className="img-fluid" objectFit="cover" />
      </Styled.ImageBox>
      <h3>{title}</h3>
      <p>{content}</p>
      {comingSoon ? (
        <p className="comingsoon">Coming soon</p>
      ) : (
        <CustomLink withArrow tag="p">
          {linkContent}
        </CustomLink>
      )}
    </Styled.Card>
  );
};

export default AcademyCard;

AcademyCard.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkContent: PropTypes.string,
  comingSoon: PropTypes.bool,
};

AcademyCard.defaultProps = {
  comingSoon: false,
  link: undefined,
  linkContent: undefined,
};
