import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from 'layout';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import Card from 'components/tutorials/card';
import Button from 'components/ui/button';
import StartUsing from 'components/ui/startUsing';

const Header = styled.header`
  padding: 180px 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  ${media.tablet} {
    padding: 120px 0 0;
  }
  ${media.mobile} {
    padding: 6rem 15px 0;
  }
`;

const StyledSection = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  padding-bottom: 0;
  padding-top: 88px;
  ${media.desktop} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 60px;
  }
  ${media.mobile} {
    grid-row-gap: 64px;
    padding-top: 64px;
  }
`;

const CTASection = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-top: 1px solid #e4e5e8;
  padding-top: 120px;
  padding-bottom: 68px;
  margin-top: 120px;
  ${media.mobile} {
    padding-top: 60px;
    margin-top: 60px;
  }
  & .subtitle {
    margin-top: 24px;
    margin-bottom: 40px;
  }
`;

const Tutorials = () => {
  const { img1, img2, img3 } = useStaticQuery(graphql`
    query AcademyPhotos {
      img1: file(relativePath: { eq: "tutorials/abtest.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2: file(relativePath: { eq: "tutorials/formanalytics.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img3: file(relativePath: { eq: "tutorials/comingSoonBlue.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout
      canonical="/tutorials/"
      metaTitle="Tutorials | Learn More with Us"
      metaDescription="Learn about A/B testing, conversion optimization, customer experience, and more. LiveSession Tutorials is free forever!"
    >
      <div className="container">
        <Header>
          <h1 className="line">
            LiveSession <span>Tutorials</span>
          </h1>
          <p className="subtitle">
            See how you can grow your business with LiveSession. Read our Tutorials and learn from
            the experts!
          </p>
        </Header>
        <StyledSection>
          <Card
            img={img1.childImageSharp.fluid}
            title="Guide to A/B testing with LiveSession"
            content="A/B testing is no longer a niche optimization technique. Did you know that 77% of companies run A/B tests on their websites?"
            link="/tutorials/guide-to-ab-testing-with-livesession/"
            linkContent="Read the Tutorial"
          />
          <Card
            img={img2.childImageSharp.fluid}
            title="Form Analytics"
            content="Online form analytics has been proven to successfully boost lead generation. How? LiveSession's tutorial will certainly help you out now!"
            link="/tutorials/form-analytics/"
            linkContent="Read the Tutorial"
          />
          <Card
            img={img3.childImageSharp.fluid}
            title="Guide to Customer Support with LiveSession"
            content="In this guide, we’re going to show you how you can improve crucial customer support factors with LiveSession."
            comingSoon
          />
          {/* <Card
            comingSoon
            img={img1.childImageSharp.fluid}
            title="Guide to UX Design with LiveSession"
            content="Learn about user experience design and improve your UX with LiveSession."
          /> */}
        </StyledSection>
        <CTASection>
          <h2>Have a tutorial idea?</h2>
          <p className="subtitle">Let us know! We&apos;ll consider every suggestion.</p>
          <Button href="mailto:hello@livesession.io?subject=Tutorial%20idea">Send your idea</Button>
        </CTASection>
      </div>
      <StartUsing />
    </Layout>
  );
};

export default Tutorials;
